<template>
    <div  >
        <div class="data-table__wrapper">
            <table class="data-table__table">
                <thead class="data-table__headings">
                <th class="data-table__heading"
                    @click="sort('id')">№
                    <span class="inline-block mx-1"
                          :class="{
                            'visible':sorting.sort !== '' && sorting.by === 'id',
                            'visible transform  rotate-0':sorting.sort === 'desc' && sorting.by === 'id',
                            'visible transform   rotate-180':sorting.sort === 'asc' && sorting.by === 'id',
                            'hidden':sorting.by !== 'id'
                        }">
                        <i class="fas fa-arrow-down "></i>
                    </span>
                </th>
                <th class="data-table__heading" v-for="(header, idx) in headers" :key="'header-'+header.value + '_'+ idx"
                    :class="{'pointer-events-none':header.name === 'Перевозки'}"
                    @click="sort(header.value)">{{ header.name }}
                    <span v-if="!header.link"
                          class="inline-block mx-1"
                          :class="{
                            'visible':sorting.sort !== '' && sorting.by === header.value,
                            'visible transform rotate-0':sorting.sort === 'desc' && sorting.by === header.value,
                            'visible transform rotate-180':sorting.sort === 'asc' && sorting.by === header.value,
                            'hidden':sorting.by !== header.value
                        }"
                    ><i class="fas fa-arrow-down "></i>
                    </span>
                </th>


                </thead>
                <tbody class="data-table__body">
                <tr class="data-table__row"
                    v-for="(data, idx) in items"
                    :key="'client-'+data.id"
                    @dblclick="show(data)">
                    <td class="p-3">{{ idx + 1 }}</td>
                    <td @dblclick="$router.push({name:'ContractDetail', params: {id:data.id}})"
                        class="p-3 "
                        :key="header.value + '_'+ idx"
                        v-for="(header, idx) in headers"
                    >
                        <span>{{ data[header.value] }}</span>
                    </td>

                </tr>
                </tbody>
            </table>
        </div>


        <Pagination dashboard :hideNext="hideNext" namespace="admin"></Pagination>

    </div>

</template>

<script>

import TableSortingMixin from '@/mixins/tableSorting'
import Pagination from '@/components/common/Pagination'
import {mapGetters} from "vuex";

export default {
    name: "DataTable",
    components: {
        Pagination
    },
    mixins: [TableSortingMixin],
    data: () => ({
        showModal: false,
        selectedItem: '',
    }),
    computed: {
        ...mapGetters({
            perPage: 'admin/perPage'
        }),
        hideNext() {
            return this.items.length === 0 || this.items.length < this.perPage;
        }
    },
    mounted() {
        this.$watch('sorting', function (newVal) {
            this.$store.commit('admin/setSorting', {sort: newVal.sort, sortBy: newVal.by})
        }, {
            deep: true, immediate: true
        })
    },
    beforeDestroy() {
        this.$store.commit('admin/destroy')
    },
    watch: {
        showModal() {
            let body = document.getElementsByTagName('body')
            if (this.showModal === true) {
                body[0].style.overflowY = 'hidden'
            } else {
                body[0].style.overflowY = 'scroll'
            }
        }
    },
    props: {
        items: {
            required: true,
            type: Array
        },
        headers: {
            required: true,
            type: Array
        }
    },
    methods: {
        show(item) {
            this.showModal = true
            this.selectedItem = item.id
        }
    }
}
</script>

<style scoped>
.data-table__wrapper{
    @apply overflow-y-scroll max-h-128  shadow-md
}
.dark .data-table__wrapper{
    box-shadow: none;
}
.data-table__table{
    @apply  w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}
.data-table__headings{
    @apply dark:bg-gray-800 bg-white text-gray-500
}
.data-table__heading{
    @apply p-3 select-none
}
.data-table__body{
    @apply dark:text-gray-400 text-gray-600
}
.data-table__row{
    @apply dark:bg-gray-800 bg-white cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300
}
::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: lightskyblue;
}
</style>
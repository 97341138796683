var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"data-table__wrapper"},[_c('table',{staticClass:"data-table__table"},[_c('thead',{staticClass:"data-table__headings"},[_c('th',{staticClass:"data-table__heading",on:{"click":function($event){return _vm.sort('id')}}},[_vm._v("№ "),_c('span',{staticClass:"inline-block mx-1",class:{
                        'visible':_vm.sorting.sort !== '' && _vm.sorting.by === 'id',
                        'visible transform  rotate-0':_vm.sorting.sort === 'desc' && _vm.sorting.by === 'id',
                        'visible transform   rotate-180':_vm.sorting.sort === 'asc' && _vm.sorting.by === 'id',
                        'hidden':_vm.sorting.by !== 'id'
                    }},[_c('i',{staticClass:"fas fa-arrow-down "})])]),_vm._l((_vm.headers),function(header,idx){return _c('th',{key:'header-'+header.value + '_'+ idx,staticClass:"data-table__heading",class:{'pointer-events-none':header.name === 'Перевозки'},on:{"click":function($event){return _vm.sort(header.value)}}},[_vm._v(_vm._s(header.name)+" "),(!header.link)?_c('span',{staticClass:"inline-block mx-1",class:{
                        'visible':_vm.sorting.sort !== '' && _vm.sorting.by === header.value,
                        'visible transform rotate-0':_vm.sorting.sort === 'desc' && _vm.sorting.by === header.value,
                        'visible transform rotate-180':_vm.sorting.sort === 'asc' && _vm.sorting.by === header.value,
                        'hidden':_vm.sorting.by !== header.value
                    }},[_c('i',{staticClass:"fas fa-arrow-down "})]):_vm._e()])})],2),_c('tbody',{staticClass:"data-table__body"},_vm._l((_vm.items),function(data,idx){return _c('tr',{key:'client-'+data.id,staticClass:"data-table__row",on:{"dblclick":function($event){return _vm.show(data)}}},[_c('td',{staticClass:"p-3"},[_vm._v(_vm._s(idx + 1))]),_vm._l((_vm.headers),function(header,idx){return _c('td',{key:header.value + '_'+ idx,staticClass:"p-3 ",on:{"dblclick":function($event){return _vm.$router.push({name:'ContractDetail', params: {id:data.id}})}}},[_c('span',[_vm._v(_vm._s(data[header.value]))])])})],2)}),0)])]),_c('Pagination',{attrs:{"dashboard":"","hideNext":_vm.hideNext,"namespace":"admin"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
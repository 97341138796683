<template>

    <div class="search">
        <div class="search__input-wrapper" v-for="(item,key,  idx) in filter" :key="'filter'+idx">
            <input :placeholder="names[key]" v-model.trim="filter[key]"
                   class="search__input">
        </div>

        <div class="search__input-wrapper">
            <button class="search__action search__action_color_green " @click="filterItems">
                Поиск
            </button>
        </div>
        <div class="search__input-wrapper search__input-wrapper_no-margin">
            <button class="search__action search__action_color_blue" @click="dropFilter">
                Сбросить
            </button>
        </div>

    </div>

</template>

<script>


export default {
    name: "Search",
    data: () => ({
        filter: {
            login: '',
            uf_contract: '',
            uf_name: '',
            uf_unp: '',
            uf_contact_phone: '',
            uf_contact_surname: '',
        },
        names: {
            login: 'Email',
            uf_contract: 'Контракт',
            uf_name: 'Юр.лицо',
            uf_unp: 'УНП',
            uf_contact_phone: 'Телефон',
            uf_contact_surname: 'Фамилия',
        },
    }),
    mounted() {
        document.addEventListener('keypress', this.pressEnter)
    },
    beforeDestroy() {
        document.removeEventListener('keypress',this.pressEnter)
    },
    methods: {
        pressEnter(){
            if(event.keyCode === 13){
                this.filterItems()
            }
        },
        filterItems() {
            this.$store.commit('admin/setFilter', this.filter)
        },
        dropFilter() {
            for (let i in this.filter) {
                this.filter[i] = ''
            }
            this.$store.commit('admin/unset')
        },

    },

}
</script>

<style scoped>
.search{
    @apply flex sm:flex-col xl:flex-row mx-1 mb-5 h-full
}
.search__input-wrapper{
    @apply sm:w-full xl:w-1/6 mr-0.5
}
.search__input{
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5 mt-2 text-base dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}
.search__action{
    @apply px-4 py-2.5 mt-2 w-full text-white
}
.search__action_color_green{
    @apply bg-green-400
}
.search__action_color_blue {
    @apply bg-blue-400
}
.search__input-wrapper_no-margin{
    @apply mr-0
}
</style>
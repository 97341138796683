<template>
    <div>
        <Search></Search>
        <DataTable :headers="headers" :items="contracts"></DataTable>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Search from '@/components/user/admin/Search'
import DataTable from '@/components/user/admin/DataTable'
export default {
    name: "ContractsAll",
    components: {Search, DataTable},
    data:()=> ({
        headers: [
            {name: 'Пользователь', value: 'login'},
            {name: 'Контракт', value: 'uf_contract'},
            {name: 'Телефон пользователя', value: 'uf_contact_phone'},
            {name: 'Наименование юр.лица', value: 'uf_name'},
            {name: 'УНП', value: 'uf_unp'},
            {name: 'Страна юр.лица', value: 'uf_org_country'},
            {name: 'Баланс', value: 'balance'},
        ]
    }),
    computed: {
        ...mapGetters({
            contracts: 'admin/contracts',
            active: 'admin/group'
        }),

    },
}
</script>

<style scoped>
::-webkit-scrollbar {
    display: none;
}
</style>